<template>
  <v-row justify="center">
    <v-dialog value="true" persistent max-width="700px">
      <v-card>
        <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
          <v-toolbar-title>
            <span class="headline">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="closeDialog">mdi-close</v-icon>
        </v-toolbar>
        <v-form ref="form" v-model="valid" @submit.prevent="salvar">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="col-no-padding">
                  <span class="subtitle-2">Identificação</span>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="cliente.nome"
                    label="Nome*"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="cliente.nomeFantasia"
                    label="Nome Fantasia"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    v-model="cliente.cpfCnpj"
                    label="CNPJ ou CPF"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="cliente.descricao"
                    label="Descrição"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-8">
                <v-col cols="6" class="col-no-padding">
                  <span
                    class="subtitle-2"
                    title="Essa imagem que aparecerá na timeline das publicações"
                    >{{ isInfluencers() ? "Imagem de perfil" : "Logo" }}</span
                  >
                </v-col>
                <v-col cols="6" class="col-no-padding">
                  <!-- <v-checkbox
                    v-model="cliente.flgDefault"
                    label="Cliente default"
                    title="Define o cliente default das publicações."
                  /> -->
                </v-col>
                <v-col align="left" cols="2">
                  <avatar
                    large
                    :avatarUrl="cliente.logoUrl"
                    :loading="loadingLogo"
                  />
                </v-col>
                <v-col align="left" cols="4">
                  <v-form enctype="multipart/form-data">
                    <label
                      for="logo"
                      style="cursor: pointer"
                      class="caption"
                      title="Add logo cliente"
                    >
                      <v-icon color="dark" for="logo">mdi-camera</v-icon>
                      <input
                        id="logo"
                        style="display: none"
                        type="file"
                        @change="uploadLogo($event)"
                      />
                    </label>
                  </v-form>
                </v-col>
                <v-col align="right" cols="6">
                  <v-select
                    v-model="cliente.classeCliente.id"
                    label="Nível"
                    :items="niveis"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-expansion-panels focusable multiple v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="subtitle-2">Endereço</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-mask="'#####-###'"
                            v-model="cliente.endereco.cep"
                            label="CEP"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" align-self="center">
                          <v-btn
                            class="ml-2"
                            color="primary"
                            outlined
                            :loading="loadingBtn"
                            hide-datais
                            @click="consultarCEP"
                            >Consultar CEP</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.endereco.logradouro"
                            label="Endereço (Rua,Av.,etc)*"
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                          <v-autocomplete
                            v-model="cliente.endereco.estado"
                            :items="estados"
                            label="Estado"
                            data-vv-name="Estado"
                            item-text="uf"
                            item-value="uf"
                            prepend-icon="mdi-map"
                            @change="carregarCidades"
                            :rules="[rules.required]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" md="5" sm="12">
                          <v-autocomplete
                            v-model="cliente.endereco.cidadeIbge"
                            :items="cidades"
                            :loading="loadingCidades"
                            label="Cidade*"
                            item-text="nome"
                            item-value="ibge"
                            no-data-text="Selecione um estado, para aparecer as cidades"
                            :rules="[rules.required]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                          <v-text-field
                            v-model="cliente.endereco.bairro"
                            label="Bairro"
                            :rules="[rules.required]"
                            required
                          />
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-model="cliente.endereco.numero"
                            label="Número"
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-model="cliente.endereco.complemento"
                            label="Complemento"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <span class="subtitle-2">Contato</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.nomeContato"
                            label="Nome"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="cliente.telefone"
                            label="Telefone"
                            prepend-icon="mdi-phone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            v-model="cliente.celular"
                            label="Celular"
                            prepend-icon="mdi-cellphone-iphone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.email"
                            label="E-mail"
                            prepend-icon="mdi-email-outline"
                            :rules="[rules.email]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.site"
                            label="Site"
                            prepend-icon="mdi-wan"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.facebook"
                            label="Facebook"
                            prepend-icon="mdi-facebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.instagram"
                            label="Instagram"
                            prepend-icon="mdi-instagram"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.twitter"
                            label="Twitter"
                            prepend-icon="mdi-twitter"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="cliente.linkedin"
                            label="Linkedin"
                            prepend-icon="mdi-linkedin"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
            <small class="error--text">*campos obrigatórios</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btnCancelar" text @click="closeDialog">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              type="submit"
              :loading="loadingSalvar"
            >
              <span class="text-capitalize">Salvar</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import avatar from "../../components/avatar";
import { CepService, ClientService, EstadoService } from "../../services";

export default {
  components: { avatar },
  props: {
    callback: {
      type: Function
    },
    closeDialog: {
      type: Function
    },
    cliente: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    valid: false,
    editedIndex: -1,
    rules: {
      required: value => !!value || "Campo obrigatório.",
      email: value => {
        if (!value) return true;
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      }
    },
    niveis: [
      { text: "1 Estrela", value: 1 },
      { text: "2 Estrelas", value: 2 },
      { text: "3 Estrelas", value: 3 }
    ],
    loadingSalvar: false,
    loading: false,
    loadingCidades: false,
    loadingBtn: false,
    loadingLogo: false,
    cidades: [],
    estados: [],
    panel: [0]
  }),
  computed: {
    formTitle() {
      return this.cliente.id ? "Alterar Cliente" : "Novo Cliente";
    }
  },
  beforeMount() {
    this.estados = this.$store.getters["tipos/getEstados"];
    if (!this.estados || this.estados.length == 0) {
      EstadoService.findAll()
        .then(response => {
          this.estados = response.data;
          this.$store.commit("tipos/setEstados", this.estados);
        })
        .catch(() =>
          this.$root.showAlerta(
            "Não foi possível buscar as informações de estado"
          )
        );
    }
    if (this.cliente && this.cliente.endereco && this.cliente.endereco.estado) {
      this.carregarCidades(this.cliente.endereco.estado);
    }
  },
  methods: {
    salvar() {
      if (this.$refs.form.validate()) {
        this.loadingSalvar = true;
        ClientService.salvar(this.cliente)
          .then(response => {
            this.$root.showSucesso(response.data);
            this.callback();
          })
          .catch(error => {
            if (error.status != 401) {
              this.$root.showErro(error.data);
            }
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro(
          "Verifica se algum campo obrigatório não foi preenchido."
        );
      }
    },
    consultarCEP() {
      if (!this.cliente.endereco.cep) {
        this.$root.showErro("Informe o CEP para realizar a consulta.");
        return;
      }
      this.loadingBtn = true;
      CepService.consultaCep(this.cliente.endereco.cep)
        .then(response => {
          this.carregarCidades(response.data.uf);
          this.cliente.endereco.cep = response.data.cep;
          this.cliente.endereco.logradouro = response.data.logradouro;
          this.cliente.endereco.complemento = response.data.complemento;
          this.cliente.endereco.bairro = response.data.bairro;
          this.cliente.endereco.localidade = response.data.localidade;
          this.cliente.endereco.estado = response.data.uf;
          this.cliente.endereco.cidadeIbge = Number(response.data.ibge);
        })
        .catch(erro => {
          let msg = "Endereço não encontrado";
          if (erro && erro.message) {
            msg = erro.message;
          }
          if (erro.data) {
            msg = erro.data;
          }
          this.$root.showErro(msg);
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    carregarCidades(estado) {
      if (estado) {
        this.cliente.endereco.estado = estado;
        this.loadingCidades = true;
        EstadoService.getCidadesByUf(estado)
          .then(response => {
            this.cidades = response.data;
            this.loadingCidades = false;
          })
          .catch(() => {
            this.$root.showAlerta(
              "Não foi possível buscar as informações das cidades"
            );
            this.loadingCidades = false;
          });
      }
    },

    uploadLogo(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);

        this.loadingLogo = true;
        ClientService.uploadLogo(this.cliente.id, this.formData)
          .then(response => {
            this.cliente.logoUrl = response.data;
          })
          .catch(error => {
            if (error && error.data) {
              this.$root.showErro(error.data);
            } else {
              this.$root.showErro(
                "Ocorreu um erro ao enviar a logo, tente novamente mais tarde."
              );
            }
          })
          .finally(() => {
            this.loadingLogo = false;
          });
      }
    }
  }
};
</script>
