<template>
  <v-row justify="center">
    <v-dialog value="true" persistent max-width="450px">
      <v-card>
        <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
          <v-toolbar-title>
            <span class="headline">Configuração</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="closeDialog">mdi-close</v-icon>
        </v-toolbar>
        <v-form ref="form" v-model="valid" @submit.prevent="salvar">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols='12'>
                  <h4> {{cliente.nome}} </h4>
                </v-col>
                <v-col cols="12" class="col-no-padding">
                  <span class="subtitle-2">Módulos disponíveis</span>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="cliente.habilitaPostagem"
                    inset
                    hide-details
                    label="Rede Social"
                    :loading="loading"
                  >
                  </v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="cliente.habilitaProduto"
                    inset
                    hide-details
                    label="Produtos"
                    :loading="loading"
                  >
                  </v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="cliente.habilitaIngresso"
                    inset
                    hide-details
                    label="Ingressos"
                    :loading="loading"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="12" class="col-no-padding">
                  <span class="subtitle-2">Descontos</span>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="cliente.isControlePagamentoCliente"
                    inset
                    hide-details
                    :disabled="(cliente.isAssinaturaVigente || cliente.usuarioExterno) && !cleaned"
                    label="Habilitar o controle de pagamentos"
                    :loading="loading"
                  >
                  </v-switch>
                </v-col>
                <v-row class="ml-0">
                  <v-col cols="8">
                      <v-text-field
                        v-model="cliente.usuarioExternoEmail"
                        label="Usuário APP para Pagamentos"
                        :rules="[rules.required, rules.email]"
                        :disabled="(!cliente.isControlePagamentoCliente || cliente.usuarioExterno || usuarioExternoName) && !cleaned "
                        hint="Informe o e-mail do usuário que irá realizar os pagamentos e então efetue a busca"
                        persistent-hint
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      :loading="loadingUsuarioExterno"
                      @click="buscarUsuarioExterno()"
                      small
                      color="primary"
                      class="mt-5 mr-2"
                      outlined
                      rounded
                      title="Buscar usuário externo"
                      :disabled="(!cliente.isControlePagamentoCliente || cliente.usuarioExterno ) && !cleaned "
                    >
                      <v-icon>mdi-account-search</v-icon>
                    </v-btn>
                    <v-btn
                      :loading="loadingUsuarioExterno"
                      @click="cleanUsuarioExterno()"
                      small
                      color="primary"
                      class="mt-5"
                      outlined
                      rounded
                      title="Limpar Campos"
                      :disabled="!cliente.isControlePagamentoCliente || !cliente.isPagamentoPendente"
                    >
                      <v-icon>mdi-broom</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="ma-0"
                  >
                    <span class="subtitle-2"
                      >Nome do usuário: {{ cliente.usuarioExternoName }}</span
                    >
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btnCancelar" text @click="closeDialog">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :loading="loadingSalvar"
            >
              <span class="text-capitalize">Salvar</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCriarUsuario"
      @click:outside="dialogCriarUsuario = false"
      scrollable
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title> Criar Novo Usuário </v-card-title>
        <v-card-text> O usuario com o e-mail {{ cliente.usuarioExternoEmail}} não foi encontrado deseja criar um novo? </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small @click="dialogCriarUsuario = false"> cancelar </v-btn>
          <v-btn small color="primary" @click="newUsuarioExterno()"> confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { ClientService, UsuarioExternoService } from "@/services";
export default {
  props: {
    callback: {
      type: Function
    },
    closeDialog: {
      type: Function
    },
    cliente: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loadingSalvar: false,
    loading: false,
    dialogCriarUsuario: false,
    usuarioExternoEmail: '',
    loadingUsuarioExterno: false,
    valid: false,
    cleaned: false,
    rules: {
      required: value => !!value || "Campo obrigatório.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      }
    }
  }),
  methods: {
    salvar() {
      if(this.cliente.isControlePagamentoCliente && !this.$refs.form.validate()){
        return this.$root.showErro('Digite um e-mail válido')
      }
      if(this.cliente.isControlePagamentoCliente && (!this.cliente.usuarioExternoId && !this.cliente.usuarioExterno)){
        return this.buscarUsuarioExterno();
      }
      this.loadingSalvar = true;
      let id = ''
      let email = ''
      if(this.cliente.usuarioExternoId ){
        id = this.cliente.usuarioExternoId 
        email = this.cliente.usuarioExternoEmail
      } else if(this.cliente.usuarioExterno) {
        id = this.cliente.usuarioExterno.id 
        email = this.cliente.usuarioExterno.email
      }
      ClientService.updateDadosComplementares({
        id: this.cliente.id,
        habilitaPostagem: this.cliente.habilitaPostagem,
        habilitaProduto: this.cliente.habilitaProduto,
        habilitaIngresso: this.cliente.habilitaIngresso,
        isControlePagamentoCliente: this.cliente.isControlePagamentoCliente,
        ...id && {usuarioExternoId: id},
        ...email && {usuarioExternoEmail: email}
      })
        .then(() => {
          this.$root.showSucesso("Dados salvos com sucesso!");
          this.callback();
          this.closeDialog();
        })
        .catch((e) => {
          this.$root.showErro(e.data);
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },
    buscarUsuarioExterno() {
      if(this.cliente.isControlePagamentoCliente && !this.$refs.form.validate()){
        return this.$root.showErro('Digite um e-mail válido')
      }
      this.loadingUsuarioExterno = true;
      UsuarioExternoService.getByEmail(this.cliente.usuarioExternoEmail)
        .then(response => {
          this.cliente.usuarioExternoId = response.data.id;
          this.cliente.usuarioExternoName = response.data.nome;
        })
        .catch((err) => {
          if(err.status == 400){
            this.dialogCriarUsuario = true
          } else {
            this.$root.showErro(err.data.message);
          }
        })
        .finally(() => {
          this.loadingUsuarioExterno = false;
        });
    },
    cleanUsuarioExterno(){
      this.cliente.usuarioExternoId = null
      this.cliente.usuarioExternoName = null
      this.cliente.usuarioExternoEmail = null
      this.cleaned = true
      this.cliente.isAssinaturaVigente = false
      this.cliente.usuarioExterno.id = null
      this.cliente.usuarioExterno.email = null
      this.cliente.usuarioExterno.nome = null
    },
    newUsuarioExterno(){
      this.loadingUsuarioExterno = true;
      let payload = {
        email: this.cliente.usuarioExternoEmail,
        clienteId: this.cliente.id
      }
        
      UsuarioExternoService.newUsuarioExterno(payload)
        .then(response => {
          this.cliente.usuarioExternoId = response.data.id;
          this.cliente.usuarioExternoName = this.cliente.usuarioExternoEmail.split('@')[0]
          this.salvar()
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.loadingUsuarioExterno = false;
          this.dialogCriarUsuario = false
        });
    }
  }
};
</script>
