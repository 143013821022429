<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">Clientes</h2>

    <v-card>
      <v-card-text>
        <v-toolbar rounded style="margin-bottom: 40px;" elevation="0">
          <v-toolbar-title>Filtros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="filtros.nome"
            hide-details
            placeholder="Nome"
            solo
            dense
          />
          <v-text-field
            v-model="filtros.cpfCnpj"
            hide-details
            placeholder="CNPJ ou CPF"
            solo
            dense
            class="ml-4"
          />
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loadingPesquisar" @click="pesquisar">
            Pesquisar
          </v-btn>
        </v-toolbar>

        <v-card>
          <v-toolbar flat color="white">
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-divider class="mx-2" inset vertical />
            <v-spacer />
            <v-btn color="primary" class="mb-2" outlined @click="novocliente">
              Novo Cliente
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="clientes"
            :options.sync="options"
            :server-items-length="totalclientes"
            :items-per-page="itemsPerPage"
            :loading="loading"
            class="elevation-1"
            loading-text="Carregando..."
          >
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text--darken-3"
                v-text="header.text"
              />
            </template>
            <template v-slot:body="{ items }" ma-5>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-center">
                    <avatar :avatarUrl="item.logoUrl" />
                  </td>
                  <td>
                    <span v-for="n in item.classeCliente.id" :key="n">
                      <v-icon color="primary"> mdi-star </v-icon>
                    </span>
                  </td>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.cpfCnpj }}</td>
                  <td>{{ item.nomeContato }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.celular }}</td>
                  <td class="text-right">
                    <v-icon
                      class="mr-2"
                      title="Editar dados do cliente"
                      @click="editarCliente(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      v-if="item.isControlePagamentoCliente"
                      class="mr-2"
                      :color="item.isPagamentoPendente ? 'primary' : 'success'"
                      title="Editar dados do cliente"
                      >mdi-account-cash</v-icon
                    >
                    <v-icon
                      v-if="habilitaMarketplace"
                      class="mr-2"
                      title="Configurar ações do cliente"
                      @click="configurarCliente(item)"
                      >mdi-cog</v-icon
                    >
                    <v-icon
                      color="primary"
                      title="Excluir cliente"
                      @click="openDialogDelete(item)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>

    <template v-if="dialogDelete">
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="500px">
          <v-card>
            <v-form
              ref="formDelete"
              v-model="valid"
              lazy-validation
              @submit.prevent="deleteCliente"
            >
              <v-card-title>
                <span class="headline">Excluir Cliente</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <span class="title">
                        Deseja excluir esse cliente?
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="nomeExcluir"
                        label="Nome do cliente que será excluído*"
                        :hint="cliente.nome"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialogDelete = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Excluir</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <dialogCadCliente
      v-if="dialog"
      :cliente="cliente"
      :callback="salvar"
      :closeDialog="closeDialog"
    />
    <dialogConfigCliente
      v-if="dialogConfigurar"
      :cliente="cliente"
      :callback="salvar"
      :closeDialog="closeDialogConfigurar"
    />
  </v-container>
</template>

<script>
import avatar from "@/components/avatar";
import { ClientService } from "@/services";
import dialogCadCliente from "./dialogCadCliente.vue";
import dialogConfigCliente from "./dialogConfigCliente.vue";
import { Role } from "@/constants";


export default {
  components: { dialogCadCliente, avatar, dialogConfigCliente },
  data: () => ({
    valid: false,
    clientes: [],
    roles: [],
    dialog: false,
    dialogConfigurar: false,
    dialogDelete: false,
    filtros: {
      nome: "",
      cpfCnpj: ""
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    totalclientes: 0,
    options: {},
    headers: [
      {
        text: "Logo",
        value: "logo",
        align: "center",
        width: "10",
        sortable: false
      },
      { text: "Nível", value: "classeCliente.id" },
      { text: "Nome", value: "nome" },
      { text: "CNPJ ou CPF", value: "cpfCnpj" },
      { text: "Contato", value: "nomeContato" },
      { text: "Email", value: "email" },
      { text: "Celular", value: "celular" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    editedIndex: -1,
    cliente: {
      dataCadastro: "",
      nome: "",
      nomeFantasia: "",
      descricao: "",
      email: "",
      cpfCnpj: "",
      telefone: "",
      celular: "",
      nomeContato: "",
      site: "",
      instagram: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      endereco: {},
      flgDefault: false
    },
    rules: {
      required: value => !!value || "Campo obrigatório.",
      confirm: value =>
        (!!value && this.novaSenha === value) ||
        "As duas senhas precisam ser iguais!",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Endereço de email inválido.";
      }
    },
    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false,
    nomeExcluir: ""
  }),
  computed: {
    habilitaMarketplace() {
      return this.$store.getters["instalacao/getInstalacao"]
        .habilitaMarketplace;
    }
  },
  watch: {
    options: {
      handler() {
        this.paginar();
      },
      deep: true
    }
  },
  mounted() {
    this.reload();
  },

  created(){
    if(this.isMobile){
      this.$router.push('/check-in')
    }

    if(this.$store.getters["user/getUser"].roleId == Role.COMISSARIO){
      this.$router.push('/comissarios')
    }

    if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
      this.$router.push('/check-in')
    }

  },
  methods: {
    reload() {
      this.paginar();
    },

    novocliente() {
      this.cliente = {
        dataCadastro: "",
        nome: "",
        classeCliente: {},
        nomeFantasia: "",
        descricao: "",
        email: "",
        cpfCnpj: "",
        telefone: "",
        celular: "",
        nomeContato: "",
        site: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        twitter: "",
        endereco: {}
      };
      this.dialog = true;
    },

    editarCliente(item) {
      this.cliente = { ...item };
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    configurarCliente(item) {
      this.cliente = { ...item };
      this.dialogConfigurar = true;
    },

    closeDialogConfigurar() {
      this.dialogConfigurar = false;
    },

    openDialogDelete(item) {
      this.nomeExcluir = "";
      this.cliente = { ...item };
      this.dialogDelete = true;
    },

    deleteCliente() {
      if (this.$refs.formDelete.validate()) {
        if (this.cliente.nome === this.nomeExcluir) {
          this.loadingSalvar = true;
          ClientService.delete(this.cliente.id)
            .then(response => {
              this.$root.showSucesso(response.data);
            })
            .catch(error => {
              if (error.status != 401) {
                this.$root.showErro(error.data);
              }
            })
            .finally(() => {
              this.loadingSalvar = false;
              this.dialogDelete = false;
              this.reload();
            });
        } else {
          this.$root.showErro(
            `O nome do cliente, tem que ser igual a <strong>${this.cliente.nome}</strong>, para ser excluído!`
          );
        }
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },

    salvar() {
      this.closeDialog();
      this.reload();
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    paginar() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      ClientService.pagination(
        itemsPerPage,
        page ? page - 1 : 0,
        "nome",
        this.filtros
      )
        .then(response => {
          this.clientes = response.data.content;
          this.totalclientes = response.data.totalElements;
        })
        .finally(() => {
          this.loadingPesquisar = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
